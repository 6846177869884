import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const Installation = () => {
  return (
    <LayoutComponent>
      <h3>Installation</h3>

      <div className="testimonials">
        <h5>Note</h5>
        <p>
          You must be a site admin or have the required role per the permission
          scheme to install GAINSS on your project.
        </p>
      </div>
      <ol>
        <li>Log in to your Jira instance as an admin.</li>
        <li>
          Go to <strong>Apps</strong>.
        </li>
        <li>
          Click Explore more apps if Gainss-Test Intelligence Solution doesn't
          appear in the list.
        </li>
        <li>
          Search for <strong>Gainss-Test Intelligence Solution</strong>. Click
          the app tile when it appears.
        </li>
        <li>Click Try it free when the Add to Jira screen appears.</li>
        <li>
          The app installs. You can access Gainss-Test Intelligence Solution
          from the side panel on the left side of the screen.
        </li>
      </ol>
    </LayoutComponent>
  )
}

export default Installation
